var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-simple-table',{staticClass:"table-content"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("タグ名")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',{staticClass:"align-center"},_vm._l((_vm.tags),function(tag,index){return _c('tr',{key:index},[_c('td',[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-tag ")]),_c('v-chip',{style:({
            backgroundColor: `rgba(${_setup.chipColor(tag.color)}, 0.24)`,
            color: `rgba(${_setup.chipColor(tag.color)}, 1)`,
          })},[_vm._v(" "+_vm._s(tag.name)+" ")])],1),_c('td',[_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-dialog',{attrs:{"value":tag.editorDialog,"width":"500","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(dialogEditor){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipEditor){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"disabled":!_setup.hasPermission(_setup.P.TAG_EDIT),"icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _setup.openUpdateDialog(index)}}},'v-btn',{
                      ...tooltipEditor.attrs,
                      ...dialogEditor.attrs,
                    },false),{ ...tooltipEditor.on, ...dialogEditor.on }),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("このタグを編集します。")])])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" タグを更新する ")]),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"tag-display"},[_c('v-chip',{style:({
                      backgroundColor: `rgba(${_setup.chipColor(
                        _setup.selectedColor
                      )}, 0.24)`,
                      color: `rgba(${_setup.chipColor(_setup.selectedColor)}, 1)`,
                    })},[_vm._v(" "+_vm._s(_setup.editTagName)+" ")])],1),_c('v-text-field',{attrs:{"rules":[_setup.required],"label":"タグ名","type":"text"},model:{value:(_setup.editTagName),callback:function ($$v) {_setup.editTagName=$$v},expression:"editTagName"}}),_c('div',[_c('span',[_vm._v("カラー")]),_c('v-row',_vm._l((_setup.colors),function(color,cIndex){return _c('v-col',{key:color,staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"color-btn",style:({ backgroundColor: color }),on:{"click":function($event){return _setup.selectColor(cIndex + 1)}}})],1)}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"cancel","text":""},on:{"click":function($event){return _setup.closeUpdateDialog(index)}}},[_vm._v(" キャンセル ")]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_setup.editTagName},on:{"click":function($event){return _setup.submitUpdate(tag.id, index)}}},[_vm._v(" 更新する ")])],1)],1)],1),_c('v-dialog',{attrs:{"value":tag.deleteDialog,"width":"500","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(dialogActivator){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipActivator){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"disabled":!_setup.hasPermission(_setup.P.TAG_DELETE),"icon":"","x-small":"","color":"error"},on:{"click":function($event){return _setup.openDelteDialog(index)}}},'v-btn',{
                      ...tooltipActivator.attrs,
                      ...dialogActivator.attrs,
                    },false),{ ...tooltipActivator.on, ...dialogActivator.on }),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("このタグを削除します")])])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" タグを削除しますか? ")]),_c('v-card-text',[_vm._v(" この操作は取り消すことができません ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":() => _setup.deleteCompanyTag(tag.id)}},[_vm._v(" 削除する ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _setup.closeDeleteDialog(index)}}},[_vm._v(" キャンセル ")])],1)],1)],1)],1)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }